import React, { Component } from "react";
import "./protection.scss";
import Heading from '@components/headings/heading';

class Protection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.text = this.props.text;
  }
  render() {
    return (
      <div className="protection background-blue">
        <canvas className="canvas"></canvas>
        <div className="protection-inside">
          <Heading className="common-h1 userprotection-heading" type="MHeading" text={this.text.heading}></Heading>
          <Heading className="common-h1 common-h1 createrprotection-sub_heading" type="CSHeading" text={this.text.sub_heading}></Heading>
          <Heading className="common-p  userProtection-disc" type="MDisc" text={this.text.description}></Heading>
          <div >
            <Heading className="common-p " type="SHDisc" text={this.text.sub_headings[0]} color="#8B6CD8"></Heading>
            <div class="userProtection-points">
              <Heading className="common-p " type="Point" text={this.text.sub_headings[0].points} color="#8B6CD8"></Heading>
            </div>
            <Heading className="common-p userProtection-sub_headings " type="SHDisc" text={this.text.sub_headings[1]} color="#8B6CD8"></Heading>

            <Heading className="common-p " type="SHDisc" text={this.text.sub_headings[2]} color="#8B6CD8"></Heading>
          </div>
        </div>
      </div>
    );
  }
}

export default Protection;
