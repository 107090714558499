import CisLogo from "@assets/navbar-logo.png";
import Theme from "@assets/lightTheme.png";
import React, { Component } from "react";
import "./navbar.scss";
import CommonFunctions from "@functions/commonFunctions";
import rm from "@rm";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: CommonFunctions.getTheme(),
      weight: 1,
      open: true,
    };
    switch (window.location.pathname) {
      case rm.instance.urls.home:
        this.state.weight = 1;
        break;
      case rm.instance.urls.features:
        this.state.weight = 2;
        break;

      default:
        this.state.weight = 3;
        break;
    }
  }

  // onClickChangeTheme() {
  //   if (this.state.theme === "DARK") {
  //     CommonFunctions.setTheme("LIGHT");
  //   } else {
  //     CommonFunctions.setTheme("DARK");
  //   }
  //   this.setState((currentState) => {
  //     return {
  //       theme: CommonFunctions.getTheme(),
  //     };
  //   });
  // }

  render() {
    return (
      <div className="navbar">
        <div className="navbar-inside">
          <div className="navbar-logo">
            <a href={rm.instance.urls.home}>
              <div>
                <img className="navbar-logo-img" src={CisLogo} alt="logo"></img>
              </div>
              <p>Crypto Cornars</p>
            </a>
          </div>
          <div className={"navbar-links"} style={{ display: this.state.open ? "flex" : "none"}}>
            <a href={rm.instance.urls.cornars}  style={{ fontWeight: 600 }}>
              {/* {this.state.weight == 1 ? <b> Home</b> : "Home"} */} Home
            </a>
            <a href={rm.instance.urls.features} style={{ fontWeight: 600 }}>
              {/* {this.state.weight == 2 ? <b>Features</b> : "Features"} */} Features
            </a>
          </div>
          {/* <div className="navbar-theme">
            <img
              className="navbar-theme-icon"
              src={Theme}
              alt="theme"
              onClick={() => this.onClickChangeTheme()}
              id="navbar-theme-icon"
            ></img>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Navbar;
