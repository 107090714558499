import React, { Component } from 'react';
import './Contactus.css';
import Navbar from '@components/navbar/navbar';
import Vector from './images/Vector.png';
// import Apiresponse from '../Apiresponse';
import Loading from './images/loading.gif';
import { Helmet } from 'react-helmet';

import LandingPage from '@components/landingPageCommon/landingPageCommon';
import Apiresponse from '@functions/api';
import config from '@config/config.json';

class Contactus extends Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            sent: false,
            click:false,
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        var name = document.getElementById('name');
        var email = document.getElementById('email');
        var phone = document.getElementById('mobileNo');
        var message = document.getElementById('message');
        var send = true;

        if (name.value.length < 3) {
            name.className += ' error';
            name.placeholder = "Please enter your name atleast 3 characters";
            send = false;
        }else {
            name.className = "name text";
            name.placeholder = "Your Name";
        }

        if (phone.value.length !== 10) {
            phone.className += ' error';
            phone.placeholder = "Please enter 10 digit phone number";
            send = false;
        }
        else {
            phone.className = "mobileNo text";
            phone.placeholder = "Mobile Number";
        }

        var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.value.match(validRegex)) {
            email.className = "email text";
            email.placeholder = "Email Address";
        } else {
            email.className += ' error';
            email.placeholder = "Enter a valid email address";
            send = false;
        }

        if (message.value.length  ===  0) {
            message.className += ' error';
            message.placeholder = "Enter a message";
            send = false;
        } else {
            message.className = "message text";
            message.placeholder = "Message";
        }
        this.setState(curentState => {
                return {
                    loading: true,
                    sent: false,
                    click:true,
            }});
        
        if (send === true) {
            setTimeout(() => {
                this.setState(currentState => {
                        return {
                            loading: false,
                            sent: false,
                            click: true,
                        }
                    });
            }, 3000);
            Apiresponse.contactUs(name.value, phone.value, email.value, message.value).then((res) => {
                if (res) {
                    this.setState(currentState => {
                        return {
                            loading: false,
                            sent: true,
                            click: true,
                        }
                    });
                    window.alert("Thank you for contacting Us");
                }
                else {
                    this.setState(currentState => {
                        return {
                            loading: false,
                            sent: false,
                            click: true,
                        }
                    });
                    window.alert("Something went wrong");
                }
            }).catch((error) => {
                window.alert("Network is down");
             });
        }
        else {
            this.setState(currentState => {
                    return {
                        loading: false,
                        sent: false,
                        click:true,
                    }
                });
            window.alert("Please enter correct details");
        }


    }

    render() {
        return (
            <div className='contactus-upper background-blue'>
                

                <LandingPage text={config.contactUs} >
                <div className='contactus-up' >
                    <div className='contactus'>
                        

                            <div className='contactus-input'>
                                <div className='contactus-input-in'>
                                    <input className='name text' type='text' placeholder='Your Name' id='name' ></input><br></br>
                                    <input className='email text' type='text' placeholder='Email Address' id="email"></input><br></br>
                                    <input className='mobileNo text' type='text' placeholder='Mobile Number' id="mobileNo"></input><br></br>
                                    <textarea className='message' placeholder='Message' id="message"></textarea><br></br>
                                    <button className='contactus-submit' onClick={this.handleClick} disabled={this.state.loading}>{this.state.loading ?  <img className='contactus-spinner' alt="loading" src={Loading}></img>:"Send Message"}</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </LandingPage>
            </div>
        );
    }
}

export default Contactus;