import Navbar from "@components/navbar/navbar";
import React, { Component } from "react";
import "./landingPage.scss";
import CommonFunctions from "@functions/commonFunctions";
import ApiResponse from "@functions/api";
import rm from "@rm";

import Image1 from "@assets/home-landing-image-1.png";
import Image2 from "@assets/home-landing-image-2.png";
import Image3 from "@assets/home-landing-image-3.png";
import Image4 from "@assets/home-landing-image-4.png";
import Image5 from "@assets/home-landing-image-5.png";

import { Box, Button, Grid, Typography, styled } from "@mui/material";

import { Linking, Alert } from "react-native";

const url = "https://play.google.com/store/apps/details?id=com.cisapp.co";


const Heading = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "4rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textAlign: "start",
  marginLeft: "10rem",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0rem",
  },
  [theme.breakpoints.down("xs")]: {
    marginLeft: "0rem",
    textAlign: "center",
  },
}));

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      register: false,
      isLoading: false,
    };

    this.onClickRegister = this.onClickRegister.bind(this);
    this.onClickSignup = this.onClickSignup.bind(this);
  }

  openurl = async (url) => {
    const isSupported = await Linking.canOpenURL(url);
    if (isSupported) {
      await Linking.openURL(url);
    } else {
      Alert.alert("Dont know how to open this url: ${url}");
    }
  };

  onClickRegister() {
    if (this.state.register) {
      this.setState({ register: false });
    } else {
      this.setState({ register: true });
    }
  }

  async onClickSignup() {
    let send = true;
    var mobileNo = document.getElementById("mobileNo-landing");
    var email = document.getElementById("email-landing");
    if (mobileNo.value.length !== 10) {
      mobileNo.className += " error";
      mobileNo.placeholder = "Please enter 10 digit phone number";
      send = false;
    } else {
      mobileNo.className = "mobileNo-landing text";
      mobileNo.placeholder = "Mobile Number";
    }

    if (CommonFunctions.checkValidEmail(email.value)) {
      email.className = "email-landing text";
      email.placeholder = "Email Address";
    } else {
      email.className += " error";
      email.placeholder = "Enter a valid email address";
      send = false;
    }

    if (send === true) {
      this.setState((curentState) => {
        return {
          isLoading: true,
          register: this.state.register,
        };
      });
      ApiResponse.register(email.value, mobileNo.value).then((res) => {
        if (res === true) {
          window.alert(res.data.message);
        } else if (res === false) {
          window.alert("Something went wrong");
        } else {
          window.alert(res.data.message);
        }
        this.setState((curentState) => {
          return {
            isLoading: false,
            register: this.state.register,
          };
        });
      });
    }
  }

  render() {
    let popup = <div></div>;
    // if(this.state.register) {
    //     popup = <div className="popup">
    //         <div class="popup-inside">
    //         <img src={CloseImg} alt="Close" className="closePng" onClick={this.onClickRegister}/>
    //             <div>
    //             <div class="popup-heading">
    //                 <h4>Register for early access</h4>
    //             </div>
    //             <div class="popup-content">
    //                 <div>
    //                 <div class="popup-form">
    //                     <h5>Email</h5>
    //                     <input type="text" placeholder="Enter your email address" className="text" id="email-landing"/>
    //                     <h5 class='popup-phone'>Phone No.</h5>
    //                     <input type="text" placeholder="Enter your Phone No." className="text" id="mobileNo-landing"/>
    //                     <br/>
    //                     <button onClick={this.onClickSignup} disabled={this.state.isLoading}>{this.state.isLoading ? <img className='contactus-spinner' alt="loading" src={Loading}></img>:"Register"}</button>
    //                 </div>
    //                 </div>
    //             </div>
    //             </div>
    //         </div>
    //     </div>;
    // }
    return (
      <div className="landingPage">
        {/* <canvas className="canvas"></canvas> */}
        <Navbar></Navbar>
        <div className="landingPage-container">
          <div className="landingPages-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-evenly"}
                  // alignItems={"center"}
                  height={"100%"}
                  width={"100%"}
                >
                  <Heading>
                    We offer a versatile range of investment solutions for the
                    dynamic world of crypto.
                  </Heading>
                  <Button className="landingPage-button" onClick={()=>{window.location.href = rm.instance.urls.home;}}>Crypto Cornars</Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className="landingPages-image-wrapper">
                  <div className="landingPage-images">
                    <img
                      src={Image2}
                      alt="Screen 2 left"
                      className="screen2-image screen2-image-left"
                    />
                    <img
                      src={Image4}
                      alt="Screen 1"
                      className="screen1-image"
                    />
                    <img
                      src={Image3}
                      alt="Screen 2 right"
                      className="screen2-image screen2-image-right"
                    />
                  </div>
                </div>{" "}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;

// {/* <div className="landingPage-container">
//   {/* <h1 className="landingPage-heading heading-h1"> {this.props.title}  </h1> */}
//   <div className="landingPage-images">
//     <img src={Image3} alt="Screen 2 left" className="screen3-image screen3-image-right" />
//     <img src={Image2} alt="Screen 2 right" className="screen2-image screen2-image-right" />
//     <img src={Image5} alt="Screen 3 left" className="screen3-image screen3-image-left" />
//     <img src={Image4} alt="Screen 2 left" className="screen2-image screen2-image-left" />
//     <img src={Image1} alt="Screen1" className="screen1-image" />
//   </div>
//   {/* <p className="landingPage-text"> {this.props.description}</p> */}
//   {/* <button className="landingPage-button" onClick={() => this.openurl(url)}>
//     {this.props.button}
//   </button> */}
// </div> */}
