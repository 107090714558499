import React, { Component } from 'react';
import './pageRouter.scss';
import Home from '@screens/home/home';
import Footer from '@components/footer/footer';
import Features from '@screens/features/features';
import ForCreators from '@screens/forCreators/forCreators';
import ForInvestors from '@screens/forInvestors/forInvestors';
import GettingStarted from '@screens/gettingStarted/gettingStarted';
import ReferralPage from '@screens/referral/referral';
import CommonFunctions from '@functions/commonFunctions';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Terms from '@oldScreens/Terms-and-Privacy/Terms'
import Privacy from '@oldScreens/Terms-and-Privacy/Privacy'
import UserPolicy from "@oldScreens/Terms-and-Privacy/UserPolicy";
import Contactus from '@oldScreens/Contact-Us/Contactus';
import Blog from '@oldScreens/Blog/Blog';
import Blockchain from '@oldScreens/Blog/MyBlogs/Blockchain';
import Facebooksued from '@oldScreens/Blog/MyBlogs/Facebooksued';
import Databreach from '@oldScreens/Blog/MyBlogs/Databreach';
import Aboutus from '@oldScreens/AboutUs/Aboutus';
import rm from '@rm';
import ScrollToTopButton from '../components/scrollToTop/ScrollToTop';


class PageRouter extends Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.canvas = null;
        this.ctx = [];
        this.particlesArray = [];
        this.animateFunction = () => this.animate();
        this.mousePoint = {
            x: -100000000,
            y: -100000000,
            radius: 0,
        }
    }
    

    componentDidMount() {
        var fontsize;
        if (window.innerWidth < 600) {
          fontsize = window.innerWidth * 0.11547344110854503 + '%';
        }
        else {
          fontsize = window.innerWidth * 0.03255208333333 + '%';
        }
        document.getElementById("html").style.fontSize = fontsize;

        setTimeout(() => {
            this.canvas = document.getElementsByClassName('canvas');
            this.canvas2 = document.getElementsByClassName('background-blue');
            for(let j =0; j < this.canvas.length; j++) {
                this.ctx.push(this.canvas[j].getContext('2d'));
                this.canvas[j].width = this.canvas[j].offsetWidth + 1000;
                this.canvas[j].height = this.canvas2[j].offsetHeight + 1000;
                this.canvas[j].style.width = this.canvas2[j].offsetWidth;
                this.canvas[j].style.height =(250 + this.canvas2[j].offsetHeight).toString() + 'px';
                this.calculteConnect = (this.canvas[j].height / 10 * this.canvas[j].width / 10);
            }

            this.canvas1 = document.getElementsByClassName('canvas1');
            this.canvas3 = document.getElementsByClassName('background-white');
            for(let j =0; j < this.canvas1.length; j++) {
                this.ctx.push(this.canvas1[j].getContext('2d'));
                this.canvas1[j].width = this.canvas1[j].offsetWidth + 1000;
                this.canvas1[j].height = this.canvas3[j].offsetHeight + 1000;
                this.canvas1[j].style.width = this.canvas3[j].offsetWidth;
                this.canvas1[j].style.height =(125 + this.canvas3[j].offsetHeight).toString() + 'px';
                this.calculteConnect = (this.canvas1[j].height / 10 * this.canvas1[j].width / 10);
            }
            this.animateInit();
        }, 100);
    }

    animateInit() {
        let numberofParticles = 220;
        for(let j =0 ;j<this.canvas.length; j++) {
            for (let i = 0; i < numberofParticles; i++) {
                let size = (Math.random() * 3) + 1;
                let x = (Math.random() * ((this.canvas[j].width - size * 2)- (size*2)) + size*2);
                let y = (Math.random() * ((this.canvas[j].height - size * 2)- (size*2)) + size*2);
                let directionX = (Math.random() * 2) - 1;
                let directionY = (Math.random() * 2) - 1;
                let color = 'rgb(255, 255, 255)';
                this.particlesArray.push(new Particles(x, y, directionX, directionY, size, color, this.canvas[j], this.ctx[j]));
                
            }
        }
       
        this.animateFunction();
    }

    animate() {
        requestAnimationFrame(this.animateFunction);
        for(let j =0 ;j<this.canvas.length; j++) {
            this.ctx[j].clearRect(0, 0, this.canvas[j].width, this.canvas[j].height);
        }
        for (let i = 0; i < this.particlesArray.length; i++) {
            this.particlesArray[i].update(this.mousePoint.x, this.mousePoint.y);
        }
        this.connectParticles();
        setTimeout(() => {
            this.particlesArray.pop();
        }, 10000000);
        
    }

    connectParticles() {
        for (let z = 0; z < this.ctx.length; z++) {
            for (let a = 0; a < this.particlesArray.length; a++) {
                for (let b = a; b < this.particlesArray.length; b++) {
                    let distance = ((this.particlesArray[a].x - this.particlesArray[b].x) * (this.particlesArray[a].x - this.particlesArray[b].x)) + ((this.particlesArray[a].y - this.particlesArray[b].y) * (this.particlesArray[a].y - this.particlesArray[b].y));
                    if (distance < this.calculteConnect && distance > 1) {
                        this.ctx[z].strokeStyle = 'white';
                        let linewidth = this.calculteConnect / (distance * 15)
                        this.ctx[z].lineWidth = linewidth > 4 ? 4 : linewidth;
                        this.ctx[z].beginPath();
                        this.ctx[z].moveTo(this.particlesArray[a].x, this.particlesArray[a].y);
                        this.ctx[z].lineTo(this.particlesArray[b].x, this.particlesArray[b].y);
                        this.ctx[z].stroke();
                    }
                }
            }
        }
    }

    render() { 

        switch(CommonFunctions.getTheme()) {
            case "LIGHT":
                import('./lightTheme.css');
                break;
            case "DARK":
            default:
                import('./darkTheme.css');
        }

        return (
          <div>
            <Router>
              <div>
                <Switch>
                  <Route exact path={rm.instance.urls.default} component={MyApp} />
                  <Route exact path={rm.instance.urls.home} component={Home} />
                  <Route exact path={rm.instance.urls.features} component={Features} />
                  <Route exact path={rm.instance.urls.forCreators} component={ForCreators} />
                  <Route exact path={rm.instance.urls.forInvestors} component={ForInvestors} />
                  <Route exact path={rm.instance.urls.gettingStarted} component={GettingStarted} />

                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/privacy" component={Privacy} />

                  <Route exact path="/userPolicy" component={UserPolicy} />

                  <Route exact path="/contactus" component={Contactus} />
                  <Route exact path="/blogs" component={Blog} />
                  <Route exact path="/aboutus" component={Aboutus} />

                  {/* blog */}
                  <Route exact path="/blogs/blockchain" component={Blockchain} />
                  <Route exact path="/blogs/facebooksued" component={Facebooksued} />
                  <Route exact path="/blogs/databreach" component={Databreach} />

                  <Route exact path={rm.instance.urls.referral} component={ReferralPage} />
                </Switch>
              </div>
            </Router>
            {window.location.pathname === rm.instance.urls.home || window.location.pathname === rm.instance.urls.default ? <div /> : <Footer></Footer>}
            {window.location.pathname === rm.instance.urls.home || window.location.pathname === rm.instance.urls.default ? <div /> : <ScrollToTopButton></ScrollToTopButton>}
          </div>
        );
    }
}
 
export default PageRouter;

function MyApp() {
    if (CommonFunctions.getSessionStorage("logo") === null) {
        CommonFunctions.setSessionStorage("logo", "1");
    }
    return <Redirect to='/home'></Redirect> 
}


class Particles {
    constructor(x, y, directionX, directionY, size, color, canvas, ctx) {
        this.x = x;
        this.y = y;
        this.directionX = directionX;
        this.directionY = directionY;
        this.size = size;
        this.color = color;
        this.canvas = canvas;
        this.ctx = ctx;
    }

    draw() {
        this.ctx.beginPath();
        this.ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
        this.ctx.fillStyle = this.color;
        this.ctx.fill();
    }

    update(x, y) {
        let fast = 1;
        if (this.x > this.canvas.width - 10 || this.x < 10) {
            this.directionX = -this.directionX;
        }

        if(this.y > this.canvas.height - 10 || this.y < 10) {
            this.directionY = -this.directionY;
        }

        let dy = y - this.y;
        let dx = x - this.x;

        let distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < this.size + 100) {
            if (x < this.x && this.x < this.canvas.width - this.size * 10) {
                this.x += fast;
                this.directionX = Math.abs(this.directionX);
            }
            else if(this.x > this.size * 10) {
                this.x -= fast;
                this.directionX = -(Math.abs(this.directionX));
            }

            if (y < this.y-20 && this.y < this.canvas.height - this.size * 10) {
                this.y += fast;
                this.directionY = Math.abs(this.directionY);
            }
            else if(this.y >  this.size * 10) {
                this.y -= fast;
                this.directionY = -(Math.abs(this.directionY));
            }
        } else {  
            this.x += this.directionX;
            this.y += this.directionY;
        }

        this.draw();
    }
    
}
