import { Box, Typography, styled } from "@mui/material";

export const MainHeading = styled(Typography)(({ theme }) => ({
    fontDamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "4rem",
    color: "#ffffff",
    textAlign: "center",
    marginBottom: "5rem",
    marginTop:'18rem'
  }));
  
  export const SubHeading = styled(Typography)(({ theme }) => ({
    fontDamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "2.8rem",
    color: "#ffffff",
    textAlign: "center",
    marginBottom: "9rem",
  }));
  
  export const Image = styled("img")(({ theme }) => ({
    width: "7.5rem",
    height: "7.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "5.7rem",
      height: "5.7rem",
    },
  }));
  
  export const Heading = styled(Typography)(({ theme }) => ({
    fontSize: "3rem",
    fontWeight: 500,
    marginBottom: "4.6rem",
    color: "#ffffff",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    [theme.breakpoints.down('sm')] : {
      fontSize: '2.2rem'
    }
  }));
  
  export const Text = styled(Typography)(({ theme }) => ({
    fontSize: "2.3rem",
    color: "#ffffff",
    textAlign: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
  }));
  
  export const IconBox = styled(Box)(({ theme }) => ({
    width: "12rem",
    height: "12rem",
    borderRadius: "50%",
    margin: "auto",
    marginBottom: "2.5rem",
    backgroundColor: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  }));
  
  export const ExploreBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    height: "12rem",
    marginRight: "10rem",
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginRight: "0rem",
    },
  }));


























//  ************************************************************** OLD CSS ********************************
// .explore-button{
//     display: flex;
//     justify-content: flex-end;
//   }
  
//   @media (max-width: 600px) {
//     .news {
//       .news-inside {
//         .news-icons-field {
//           width: 90%;
//           .news-icon-row {
//             div {
//               margin-bottom: 3rem;
  
//               .news-icon {
//                 margin-bottom: 1.3rem;
  
//                 img {
//                   width: 5.7rem;
//                   height: 5.7rem;
//                 }
//               }
  
//               h5 {
//                 font-size: 1.5rem;
//                 // width: 100%;
//                 margin-bottom: 0.6rem;
//                 text-align: center;
//               }
  
//               p {
//                 font-size: 1.05rem;
//                 width: 13rem;
//               }
//             }
//           }
//         }
  
//         .news-news {
//           .news-articles-container {
//             margin: 0 3rem;
//             padding: 5rem 0 5rem 0;
//             h1 {
//               font-size: 2rem;
//               margin-bottom: 3.3rem;
//             }
  
//             .news-articles {
//               justify-content: center;
//               flex-direction: column;
  
//               div {
//                 margin: auto;
//                 margin-bottom: 3rem;
//                 width: 38.3rem;
//                 height: auto;
//                 padding: 2.1rem 2.5rem;
  
//                 img {
//                   margin: auto;
//                   width: 38.3rem;
//                   height: 23.6rem;
//                 }
  
//                 h5 {
//                   font-size: 2.2rem;
//                   margin-top: 1.8rem;
//                   margin-bottom: 0.7rem;
//                 }
  
//                 p {
//                   font-size: 1.6rem;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }