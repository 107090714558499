import './Blog.css';

import React from 'react';
import Navbar from '@components/navbar/navbar';
import AppSamuri from './images/appsamurai.png';
import Blockchain from './images/blockchain.jpeg';
import Databreach from './images/databreach.jpeg';
import Facebook from './images/facebook.jpeg';
import Indiamedia from './images/indianmedia.jpeg';
import Logo from './images/logo.png.png';
import Privacy from './images/privacy.jpeg';
import SocialMedia from './images/socialmedia.jpeg';
import { Helmet } from 'react-helmet';

function Blog(props) {

    let fontsize;
    if (window.innerWidth < 600) {
        fontsize = window.innerWidth * 0.165333333 + '%'
      }
      else {
        fontsize = window.innerWidth * 0.042534722 + '%'
      }

    let blogs = [
        {
            image: Blockchain,
            link: './blogs/blockchain',
            heading: 'How Blockchain Can Revolutionise Social Media And Cornars ',
            date: '10/02/2021',
            content: 'Blockchain, which began to emerge as a real-world tech option in 2016 and 2017, is poised to change IT in much the same way open-source software did a quarter-century ago. Blockchain is a system of recording information in a way that makes it difficult or impossible to change, hack, or cheat the system....',
        },

        {
            image: Facebook,
            link: './blogs/facebooksued',
            heading: 'Facebook Sued to Monopolize',
            date: '07/01/2021',
            content: 'The US Federal government and 46 states, as well as the territories of Guam and the District of Columbia, have filed parallel antitrust lawsuits against Facebook, accusing the social media platform of anti-competitive behaviour by misusing its market power to create a monopoly and suppressing its smaller challengers.....',
        },

        {
            image: Databreach,
            link: './blogs/databreach',
            heading: 'DATA BREACHING BY SOCIAL MEDIA PLATFORMS',
            date: '10/12/2020',
            content: 'Data Breaching mean the intentional or unintentional release of secure or private/confidential information to an untrusted environment. Other terms for this phenomenon include unintentional information disclosure, data leak, information leakage and also, data spill... ',
        },
        
        {
            image: Logo,
            link: 'https://connectinsingle.blogspot.com/2019/08/cis-social-network-app.html?m=1',
            heading: 'Cornars  - The First Social App Creating Private And Public Spaces In One App',
            date: '07/08/2019',
            content: 'Cornars  is a world\'s first social media platform creating public and private space in one app. Introducing accounts for the first time where users can segregate the people based on four account categories - Public, Family, Friends and work.', 
        },

        {
            image: Indiamedia,
            link: 'https://cisapp.medium.com/is-it-the-time-for-an-indian-social-network-3de324774152',
            heading: 'Social Media: Truth Or Dare?',
            date: '28/05/2020',
            content:'Users of the Internet are widely increasing day by day. About half of the world’s population are using the internet and social media to get access to the virtual world controlled by the Internet. In the virtual world, people can create a personal avatar, and simultaneously and independently explore the virtual world, participate in its activities, and communicate with others.',
        },

        {
            image: SocialMedia,
            link: 'https://medium.com/@connectinsingle/social-media-truth-or-dare-bab88ade713b',
            date: '28/05/2020',
            heading: 'Social Media: Truth Or Dare?',
            content:'Users of the Internet are widely increasing day by day. About half of the world’s population are using the internet and social media to get access to the virtual world controlled by the Internet. In the virtual world, people can create a personal avatar, and simultaneously and independently explore the virtual world, participate in its activities, and communicate with others.',
        },

        {
            image: Privacy,
            link: 'https://medium.com/@connectinsingle/are-you-worried-about-data-privacy-in-social-media-2ed5691fdf0e',
            heading: 'Are You Worried About Data Privacy In Social Media ?',
            date: '03/06/2020',
            content:'Hello everyone!! Hope you are doing well during this lockdown!! Unlike topics and trends that come and go, data privacy remains a crucial issue to eternity for internet users around the world. Users are growing warier of privacy concerns as society becomes increasingly data-driven, and stories in the media reiterate the worth of personal information.'
        },

        {
            image: AppSamuri,
            link: 'https://appsamurai.com/47-breakout-apps-and-apptreprenuers-to-watch/',
            heading: 'Cornars  Featured As Top 47 Breakout Apps In World',
            date: '13/06/2020',
            content:'As users are now more aware of their data and seeing data is the new currency, no step is taken to protect it. Cornars  has introduced the world’s first of its kind feature: Offering Public and Private Spaces in one app!',
        }

    ];


    return (
        <div className="background-blue">
        <canvas className="canvas"></canvas>
             <Helmet>
                    <title>Cornars  | Blogs</title>
                </Helmet>
            <Navbar></Navbar>
            <div id="blog" style={{fontSize: fontsize}}>
            <center><h1 className='common-h1'>Trending on Cornars </h1></center>
            <div className='blog'>
                {blogs.map((data, index) => {
                    return <a href={data.link} className='each_blog' target={data.link.search('./blogs') ? '_blank' : '_self'}>
                        <div className='blog_img'><img src={data.image} alt='app_samurai'></img></div>
                        <div className='blog_content'>
                            <div className='blog_header'>
                                <div className='blog_head'>{data.heading}
                                    <span className='blog_date'>{data.date}</span></div>
                            </div>
                            <div className='blog_divider'></div>
                            <div className='blog_content'>
                                <p className='common-p'>{data.content}
                                </p>
                            </div>
                        </div>
                        <div className='blog_arrow'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 11.871l-5-4.871v3h-19v4h19v3z" /></svg></div>
                    </a>;
                })}
            </div></div>
        </div>
    );
}

export default Blog;