import React from 'react';
import Navbar from '@components/navbar/navbar';
import './Aboutus.scss';
import { Helmet } from 'react-helmet';
import LandingPage from '@components/landingPageCommon/landingPageCommon';
import userProfileScreen from '@assets/userProfileScreen.png';
import config from '@config/config.json';
import MessengerChatImg from './images/messengerChatImg.png';
import FeedPostImg from './images/feedPostImg.png'
import publicExplore from './images/publicExplore.png'

function Aboutus(props) {

    let fontsize;
    if (window.innerWidth < 600) {
        fontsize = window.innerWidth * 0.165333333 + '%'
      }
      else {
        fontsize = window.innerWidth * 0.042534722 + '%'
      }
  

    return (
        <div className="aboutUs">
            <LandingPage text={config.aboutUs.segment_1} image={publicExplore} imageLeft={MessengerChatImg} imageRight={FeedPostImg} type="aboutUs">
                {config.aboutUs.segment_2.description}
            </LandingPage>
        </div>
    );
}

export default Aboutus;