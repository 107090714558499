import React, { Component } from "react";
import Accounts from "./accounts/accounts";
import Messaging from "./messaging/messaging";
import UserBenefits from "./userBenefits/userBenefits";
import Ecosystem from "./ecosystem/ecosystem";
import Protection from "./protection/protection";
import config from "@config/config.json";
import WhiteComponent from "@components/singleImageWhiteComponent/whiteComponent";
import BlueComponent from "@components/singleImageBlueComponent/blueComponent";
import CornersImg from "@assets/cornersImg.png";
// import LandingPage from '@components/landingPageCommon/landingPageCommon';
import userProfileScreen from "@assets/userProfileScreen.png";
import DrawerImg from "@assets/drawerImg.png";
import LandingPage from "./landingPage/landingPage";
import CommonFunctions from "@functions/commonFunctions";
import landing2 from "../../assets/home-landing-image-4.png";
import landing3 from "../../assets/home-landing-image-3.png";
import whiteComp from "../../assets/whitecomp.png";
import feature4 from "@assets/feature4.png";
import feature5 from "@assets/feature5.png";
import portfolio2 from "../../assets/portfolio.png";
import cryptoImage from "../../assets/cryptoImage.png";
import investmentImage from "../../assets/investmentImage.png";
import reportImage from "../../assets/reportImage.png";

// 500*690 Image Size

class ForUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.text = config.features;
  }
  render() {
    return (
      <div className="forUsers">
        <div className="background-blue">
          <canvas className="canvas"></canvas>
          <LandingPage
            title={CommonFunctions.textToHTML(this.text.segment_1.heading)}
            description={CommonFunctions.textToHTML(
              this.text.segment_1.description
            )}
            button={CommonFunctions.textToHTML(this.text.segment_1.button)}
          ></LandingPage>

          {/* <LandingPage text={this.text.segment_1} image={DrawerImg} imageLeft={userProfileScreen} imageRight={CornersImg}></LandingPage> */}
          {/* <Accounts text={this.text.segment_2}></Accounts>
            <Messaging text={this.text.segment_3}></Messaging> */}

          <WhiteComponent
            text={this.text.segment_4}
            image={portfolio2}
          ></WhiteComponent>
          <BlueComponent
            text={this.text.segment_5}
            image={portfolio2}
          ></BlueComponent>

          <WhiteComponent
            text={this.text.segment_10}
            image={cryptoImage}
          ></WhiteComponent>
          <BlueComponent
            text={this.text.segment_11}
            image={investmentImage}
          ></BlueComponent>

          <WhiteComponent
            text={this.text.segment_4}
            image={reportImage}
          ></WhiteComponent>
          <BlueComponent
            text={this.text.segment_12}
            image={portfolio2}
          ></BlueComponent>

          {/* <UserBenefits text={this.text.segment_5}></UserBenefits> */}
          {/* <Ecosystem text={this.text.segment_6}></Ecosystem>
                <Protection text={this.text.segment_7}></Protection> */}
        </div>

      </div>
    );
  }
}

export default ForUsers;
