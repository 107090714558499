import React, { Component } from "react";

import Analytics from "../images/analytics.png";
import Bars from "../images/bars.png";
import Bitcoin from "../images/bitcoin.png";
import Trackers from "../images/trackers.png";
import Tax from "../images/tax.png";
import Last from "../images/last.png";
import { Linking, Alert } from "react-native";
import { Box, Grid } from "@mui/material";

import { MainHeading, SubHeading, IconBox, Image, Heading, Text, ExploreBox } from './mainFeaturesCSS'
import './mainFeatures.scss'


const url = "/features";

class MainFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.text = this.props.text;
  }
  openurl = async (url) => {
    const isSupported = await Linking.canOpenURL(url);
    if (isSupported) {
      // await Linking.openURL(url);
      window.location.href = url;
    } else {
      Alert.alert("Dont know how to open this url: ${url}");
    }
  };

  render() {
    return (
      <>
        <div className="news blueComponenet">
          <MainHeading>Features of Crypto Cornars</MainHeading>
          <SubHeading>
            We offer many features which makes Crypto Cornar a one-stop solution
            for all crypto investments needs. Main features are as follow
          </SubHeading>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconBox>
                  <Image src={Trackers} alt="Decentrialized Icon" />
                </IconBox>
              </Box>
              <Heading>{this.text.features[0].heading}</Heading>
              <Text>{this.text.features[0].description}</Text>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconBox>
                  <Image src={Tax} alt="Decentrialized Icon" />
                </IconBox>
              </Box>
              <Heading>{this.text.features[1].heading}</Heading>
              <Text>{this.text.features[1].description}</Text>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconBox>
                  <Image src={Analytics} alt="Decentrialized Icon" />
                </IconBox>
              </Box>
              <Heading>{this.text.features[2].heading}</Heading>
              <Text>{this.text.features[2].description}</Text>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconBox>
                  <Image src={Bitcoin} alt="Decentrialized Icon" />
                </IconBox>
              </Box>
              <Heading>{this.text.features[3].heading}</Heading>
              <Text>{this.text.features[3].description}</Text>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconBox>
                  <Image src={Bars} alt="Decentrialized Icon" />
                </IconBox>
              </Box>
              <Heading>{this.text.features[4].heading}</Heading>
              <Text>{this.text.features[4].description}</Text>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconBox>
                  <Image src={Last} alt="Decentrialized Icon" />
                </IconBox>
              </Box>
              <Heading>{this.text.features[5].heading}</Heading>
              <Text>{this.text.features[5].description}</Text>
            </Grid>
            {/* <Grid item xs={12}> */}
            {/* <ExploreBox> */}
            <div className="button-container">
              <button className="button" onClick={() => this.openurl(url)}>
                Explore Features
              </button>
            </div>
            {/* </ExploreBox> */}
            {/* </Grid> */}
          </Grid>
        </div>
      </>
    );
  }
}

export default MainFeatures;
