import React, { Component } from "react";
import LandingPage from "./landingPage/landingPage";
import Features from "./features/features";
import WhiteComponent from "@components/singleImageWhiteComponent/whiteComponent";
import BlueComponent from "@components/singleImageBlueComponent/blueComponent";
import RewardsImg from "@assets/home-segment-3-image.png";
import DrawerImg from "@assets/home-segment-2-image.png";
import config from "@config/config.json";
import CommonFunctions from "@functions/commonFunctions";
import "./home.scss";
import Logo from "@components/splash/Logo";
import Footer from "@components/footer/footer";
import PrivateChatHome from "./images/privateChatHome.png";

import DataPresent from "./dataPresent/dataPresent";
import MainFeatures from "./mainFeatures/mainFeatures";
import portfolio from "../../assets/portfolio.png";
import ScrollToTopButton from "../../components/scrollToTop/ScrollToTop";

class Home extends Component {
  constructor(props) {
    super(props);
    this.text = config.home;
    if (CommonFunctions.getSessionStorage("logo") === "1") {
      this.state = {
        logo: true,
      };
    } else {
      this.state = {
        logo: false,
      };
    }
  }

  async componentDidMount() {
    setTimeout(() => {
      this.setState((currentState) => {
        return {
          logo: false,
        };
      });
      CommonFunctions.setSessionStorage("logo", "0");
    }, 4000);
  }

  render() {
    return (
      <div
        className="home"
        style={
          this.state.logo
            ? { overflowY: "hidden", position: "relative", height: "100vh" }
            : { position: "relative" }
        }
      >
        {/* {this.state.logo ? <div className="home-intro">{<Logo></Logo>}</div> : ""} */}
        <div className="background-blue">
          <canvas className="canvas"></canvas>
          <LandingPage
            title={CommonFunctions.textToHTML(this.text.segment_1.heading)}
            description={CommonFunctions.textToHTML(
              this.text.segment_1.description
            )}
            button={CommonFunctions.textToHTML(this.text.segment_1.button)}
          ></LandingPage>
          <DataPresent></DataPresent>

          <BlueComponent
            text={this.text.segment_2}
            image={portfolio}
          ></BlueComponent>
          {/* <canvas className="canvas"></canvas> */}
          <WhiteComponent
            text={this.text.segment_3}
            image={portfolio}
            imageClassName="homeBlockChain"
          ></WhiteComponent>
          <MainFeatures text={this.text.segment_7}></MainFeatures>
        </div>
        <Footer></Footer>
        <ScrollToTopButton></ScrollToTopButton>

      </div>
    );
  }
}

export default Home;
