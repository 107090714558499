import React, { Component } from "react";
import "./dataPresent.scss";
import { Box, Container, Grid, Typography, styled } from "@mui/material";

const DataContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  marginLeft:'5rem',
  [theme.breakpoints.down('sm')] : {
    marginLeft:'auto',
  }
}));

const Numbers = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "4.8rem",
  color: "#FFF",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "2.8rem",
  color: "#FFF",
}));

class DataPresent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container maxWidth="lg" sx={{marginTop:'16rem', marginBottom:'-4rem'}}>
        <Grid container spacing={10}>
          <Grid item xs={6} sm={6} md={3}>
            <DataContainer>
              <Numbers className="dataPresent-number">20k+</Numbers>
              <Text className="dataPresent-para">Cryptocurrencies</Text>
            </DataContainer>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <DataContainer>
              <Numbers className="dataPresent-number">100+</Numbers>
              <Text className="dataPresent-para">Exchanges' support</Text>
            </DataContainer>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <DataContainer>
              <Numbers className="dataPresent-number">5 yrs+</Numbers>
              <Text className="dataPresent-para">Historical Data</Text>
            </DataContainer>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <DataContainer>
              <Numbers className="dataPresent-number">0%</Numbers>
              <Text className="dataPresent-para">Taxation fee</Text>
            </DataContainer>
          </Grid>
        </Grid>
        <Box height={{md:'50px',sm:'75px',xs:'100px'}}></Box>
      </Container>
    );
  }
}

export default DataPresent;
